<template>
  <div class="min-h-contain px-5">
    <div class="pb-3">
      <div class="text-left text-3xl font-bold flex">
        <p class="cursor-pointer" @click="$router.push('/document-client')">Documents /</p>
        <p class="cursor-pointer ml-2" @click="$router.go(-1)">{{ documentType.split(' ')[0] }} /</p>
        <p class="text-3xl font-bold text-yellow-500 ml-2">Details</p>
      </div>
    </div>
    <!-- End Title  -->
    <!-- <div>
      <p class="font-semibold">Detail</p>
    </div> -->
    <!-- Document Data -->
    <div class="bg-white mt-5 w-full px-7 py-5 flex justify-between items-center rounded-lg">
      <div>
        <table class="text-left">
          <tr>
            <th>Document Name</th>
            <td class="pl-2">{{ toDocumentName(dataTable.content[0].fileUrl) }}</td>
          </tr>
          <tr>
            <th>Type</th>
            <td class="pl-2">{{ documentType }}</td>
          </tr>
          <tr>
            <th>Last Modified</th>
            <td class="pl-2">{{ moment(dataTable.content[0].updatedDate) }}</td>
          </tr>
        </table>
      </div>
      <div class="flex gap-5" v-if="afterSave === true && dataTable.content[0].registrationDocument.type != 'IMAGE'">
        <button :class="`${!Approve ? 'bg-yellow' : 'bg-green-200'} w-36 p-3 font-semibold  text-white text-center rounded-lg`" @click="approveSucces">
          {{ !Approve ? 'Agree' : 'Approved' }}
        </button>
        <div class="relative inline-block drop">
          <button class="w-40 p-3 font-semibold bg-yellow-secondary text-yellow text-left pl-6 rounded-lg relative">Respond<img src="../../assets/Vector.png" class="absolute right-8 top-5" /></button>
          <div class="dropdowns absolute z-10">
            <button class="w-40 p-3 font-semibold bg-neutral-200 text-black text-center rounded-lg hover:bg-neutral-300" @click="showChangeFile = true">Change File</button>
            <button class="w-40 p-3 font-semibold bg-neutral-200 text-black text-center rounded-lg hover:bg-neutral-300" @click="showCOmmentFunct">Comment</button>
          </div>
        </div>
        <button class="p-3 bg-yellow rounded-lg" @click="showModalHistory = true"><img src="../../assets/images/change.png" alt="" /></button>
      </div>
    </div>

    <!-- ChangeFile Modal -->
    <div v-if="showChangeFile === true">
      <transition name="model">
        <div class="modal-mask-ChangeFile">
          <div class="modal-wrapper-ChangeFile">
            <div class="modal-dialog-ChangeFile">
              <div class="modal-content-ChangeFile">
                <!-- Isi -->
                <div class="modalChangeFile">
                  <!-- <div class="w-full">
                    <span class="cursor-pointer mb-2" @click="showChangeFile = false"><strong>X</strong></span>
                  </div> -->
                  <div class="text-center mb-6 justify-center">
                    <p class="text-2xl font-bold mb-5">Change Document</p>
                    <p class="text-14 font-normal">Please state your reason, and we will reach out to you soon</p>
                  </div>
                  <div class="border-solid">
                    <div @drop.prevent="dragFile" @dragover.prevent v-if="!this.fileList.length" @dragover="dragover" class="border-dashed border-2 w-3/5 px-8 py-5 text-center m-auto mb-4 rounded-lg">
                      <img src="@/assets/uploadImage.png" class="block mx-auto" />
                      <p class="my-4 text-sm">
                        Drag & Drop File <br />
                        Or
                      </p>
                      <input type="file" @change="onChange" ref="file" id="file" class="w-px h-px opacity-0 overflow-hidden absolute" />
                      <label for="file" class="cursor-pointer border px-10 py-2 font-semibold bg-yellow text-white text-center rounded-lg">Select File</label>
                    </div>
                    <div v-if="this.fileList.length" class="border-dashed border-2 w-3/5 px-8 py-5 m-auto mb-4 relative">
                      <p @click="remove" class="cursor-pointer bg-red-400 w-6 h-6 text-base font-semibold text-center text-white rounded-full absolute right-5">x</p>
                      <div v-for="data in fileList" :key="data.id" class="w-full h-auto py-8">
                        <p class="h-auto text-center truncate ... w-full">{{ data.name }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="w-3/5 m-auto mb-4">
                    <p class="px-4 py-1 text-xs font-medium">Comment</p>
                    <textarea v-model="comment" class="h-52 text-sm border-grey-field border resize-none px-3 py-3 focus:outline-none focus:ring focus:ring-white w-full rounded-lg" />
                  </div>
                  <div class="flex justify-center gap-10">
                    <button class="w-28 h-9 font-semibold bg-yellow text-white text-center rounded-lg" @click="saveChangeFile">Save</button>
                    <button class="w-28 h-9 font-semibold border-yellow-400 border-2 text-yellow text-center rounded-lg" @click="cancelChangeFile">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End ChangeFile Modal -->

    <!-- Comment Modal -->
    <div v-if="showComment === true">
      <transition name="model">
        <div class="modal-mask-ChangeFile">
          <div class="modal-wrapper-ChangeFile">
            <div class="modal-dialog-ChangeFile">
              <div class="modal-content-ChangeFile">
                <!-- Isi -->
                <div class="modalComment">
                  <!-- <div class="w-full">
                    <span class="cursor-pointer mb-2" @click="showChangeFile = false"><strong>X</strong></span>
                  </div> -->
                  <div class="text-center mb-6 justify-center">
                    <p class="font-bold mb-5 text-2xl">Comment on File</p>
                    <p class="text-14 font-normal">Please state your reason, and we will reach out to you soon</p>
                  </div>
                  <div class="w-3/5 m-auto mb-4">
                    <p class="px-4 py-1 text-xs font-medium">Comment</p>
                    <textarea v-model="comment" class="h-52 text-sm border-grey-field border resize-none px-3 py-3 focus:outline-none focus:ring focus:ring-white w-full rounded-lg" />
                  </div>
                  <div class="flex justify-center gap-10">
                    <button class="w-28 h-9 font-semibold bg-yellow text-white text-center rounded-lg" @click="saveComment">Save</button>
                    <button class="w-28 h-9 font-semibold border-yellow-400 border-2 text-yellow text-center rounded-lg" @click="showComment = false">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End Comment Modal -->

    <!-- Approve Modal -->
    <div v-if="showApprove === true">
      <transition name="model">
        <div class="modal-mask-Approve">
          <div class="modal-wrapper-Approve">
            <div class="modal-dialog-Approve">
              <div class="modal-content-Approve">
                <!-- Isi -->
                <div class="modalApprove">
                  <div class="text-center mb-6 justify-center">
                    <p class="text-xl font-semibold">Change Document?</p>
                    <p class="text-11">Please state your reason</p>
                  </div>
                  <div class="boxDropzone">
                    <drop-zone></drop-zone>
                    <SelectFile />
                  </div>
                  <div class="comment">
                    <p>Comment</p>
                    <form>
                      <input type="text" />
                    </form>
                  </div>
                  <div class="flex items-center justify-evenly">
                    <button class="w-32 p-3 font-semibold bg-yellow text-white text-center rounded-lg" @click="ApproveSucces">Save</button>
                    <button class="w-32 p-3 font-semibold bg-yellow-secondary text-yellow text-center rounded-lg" @click="showApprove = false">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End Approve Modal -->

    <!-- History Modal -->
    <div v-if="showModalHistory === true">
      <transition name="model">
        <div class="modal-mask-History">
          <div class="modal-wrapper-History">
            <div class="modal-dialog-History">
              <div class="modal-content-History">
                <!-- Isi -->
                <div class="modalHistory overflow-y-auto">
                  <div class="w-full relative">
                    <span class="cursor-pointer mb-2 right-0 -top-5 absolute" @click="showModalHistory = false"><strong>&#10005;</strong></span>
                  </div>
                  <div class="text-center mb-8 justify-center">
                    <p class="font-bold mb-5 text-2xl">Document History</p>
                  </div>
                  <div class="m-auto">
                    <div class="flex w-full">
                      <span class="text-sm font-semibold" style="width: 38%">Modified By</span>
                      <span class="w-1/5 text-sm font-semibold">Date</span>
                      <span class="w-1/5 text-sm font-semibold">Comment</span>
                      <span class="w-1/5 text-sm font-semibold">Status</span>
                    </div>
                    <hr />
                    <div class="flex py-2 w-full" v-for="(data, idx) in documentHistory" :key="idx">
                      <span class="text-sm font-semibold leading-snug" style="width: 38%">{{ data.createdBy }}</span>
                      <span class="text-sm font-medium w-1/5">{{ moment(data.createdDate) }}</span>
                      <span class="text-sm font-medium w-1/5">{{ data.comment ? data.comment : '-' }}</span>
                      <span class="text-sm font-medium w-1/5">{{ data.status ? data.status : '-' }}</span>
                    </div>

                    <!-- <div class="flex">
                      <p class="text-sm font-semibold pr-16 text-yellow-400">First Change</p>
                      <p class="text-sm font-medium w-32">31/3/2022</p>
                      <div class="w-80">
                        <p class="text-sm font-medium">Client comment</p>
                        
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End Comment History -->

    <!-- View PDF -->
    <div class="w-full py-5 -mt-44 flex justify-between items-center">
      <iframe type="application/pdf" :src="dataTable.content[0].fileUrl" style="height: 700px; width: 100%; margin-top: 200px"></iframe>
    </div>
    <!-- End View PDF -->
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import axios from 'axios'
export default {
  name: 'DocumentApprove',
  components: {
    // PDFViewer,
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    DropZone: () => import(/* webpackChunkName: "DropZone" */ '@/components/Media/DropZone3'),
    SelectFile: () => import(/* webpackChunkName: "DropZone" */ '@/components/Media/SelectFile')
  },
  data: () => ({
    showApprove: false,
    Approve: false,
    afterSave: true,
    showChangeFile: false,
    showComment: false,
    showModalHistory: false,
    isDisabledPopup: false,
    fileList: [],
    comment: '',
    typeImg: '',
    fileName: '',
    apiImg: {
      uploadUrl: '',
      key: ''
    },
    documentHistory: [],
    response: { image: '' },
    dataTable: {
      content: [
        {
          fileUrl: '',
          status: '',
          registrationDocument: { id: '' }
        }
      ]
    }
  }),
  computed: {
    documentType() {
      return this.$route.query.is
    },
    documentId() {
      return this.$route.query.id
    },
    isModalNotDisabled() {
      return JSON.parse(localStorage.getItem('talent')) ? JSON.parse(localStorage.getItem('talent')).disabled : false
    }
  },
  mounted() {
    this.getDataMitra()
  },
  methods: {
    ...mapActions('client', ['GET_DOCUMENT_LIST', 'SET_FILES', 'GET_DATA_DOCUMENT', 'GET_HISTORY_DOCUMENT_BY_ID', 'GET_URL_API_IMG', 'SET_DOCUMENT_APPROVE', 'SET_DOCUMENT_ADMIN_REPLACE']),
    ...mapActions('common', ['showLoading', 'hideLoading']),
    toDocumentName(url) {
      const urlArr = url.split('/')
      return urlArr[urlArr.length - 1]
    },
    succes() {
      this.$router.push({ path: '/trash' })
    },
    testing(test) {
      let binary = atob(test.split(',')[1])
      let array = []
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i))
      }
      return array
    },
    async getDataMitra() {
      this.dataTable = await this.GET_DOCUMENT_LIST({
        page: 0,
        size: 10,
        registrationDocumentName: this.documentType,
        id: this.documentId
      })
      if (this.dataTable.content[0].status === 'APPROVED') this.Approve = true
      await this.getHistory()
    },
    moment(date) {
      return moment(date).local().format('DD MMM YYYY HH:mm')
    },
    async saveComment() {
      this.showLoading()
      await this.SET_DOCUMENT_ADMIN_REPLACE({
        docId: this.dataTable.content[0].registrationDocument.id,
        data: {
          comment: this.comment,
          partnerDocumentUrl: this.dataTable.content[0].fileUrl
        }
      })
      this.afterSave = true
      this.showComment = false
      this.hideLoading()
    },
    async upladImageControl(url) {
      let baseUrl
      switch (process.env.NODE_ENV) {
        case 'production':
          baseUrl = 'https://prod-api-v4.g2academy.co'
          break
        case 'staging':
          baseUrl = 'https://staging-api-v4.g2academy.co'
          break
        case 'beta':
          baseUrl = 'https://dev-api-v4.g2academy.co'
          break
      }
      const { data } = await axios({
        method: 'GET',
        url: `${baseUrl}/uploads?contentType=${this.typeImg}`
      })
      this.apiImg = data
      let binary = atob(url.split(',')[1])
      let array = []
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i))
      }
      let blobData = new Blob([new Uint8Array(array)], { type: this.typeImg })
      await fetch(this.apiImg.uploadUrl, {
        method: 'PUT',
        body: blobData
      })
    },
    createImage(file) {
      this.typeImg = file.type
      this.fileName = file.name
      let reader = new FileReader()
      reader.onload = (e) => {
        this.response.image = e.target.result
      }
      reader.readAsDataURL(file)
    },
    async saveChangeFile() {
      if (!this.fileList.length) {
        alert('Image harus di isi')
        return
      }
      if (this.typeImg === 'application/pdf') {
        this.showLoading()
        await this.upladImageControl(this.response.image)
        await this.SET_DOCUMENT_ADMIN_REPLACE({
          docId: this.dataTable.content[0].registrationDocument.id,
          data: {
            comment: this.comment,
            originalFileName: this.fileName,
            partnerDocumentUrl: `${this.apiImg.uploadUrl}/${this.apiImg.key}`
          }
        })
        this.hideLoading()
        this.getDataMitra()
        this.afterSave = true
        this.showChangeFile = false
        return
      }
      this.errorMessage('Document harus format pdf')
    },
    cancelChangeFile() {
      this.showChangeFile = false
      this.fileList = []
      this.comment = ''
    },
    dragover(event) {
      event.preventDefault()
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100')
        event.currentTarget.classList.add('bg-yellow-200')
      }
    },
    onChange() {
      this.fileList = [...this.$refs.file.files]
      this.createImage(this.$refs.file.files[0])
    },
    remove(i) {
      this.fileList.splice(i)
    },
    dragFile(e) {
      e.preventDefault()
      let droppedFiles = e.dataTransfer.files
      this.fileList.push(droppedFiles[0])
      this.createImage(this.fileList[0])
    },
    succes() {
      this.$router.push({ path: '/trash' })
    },
    async approveSucces() {
      if (this.Approve) return
      await this.SET_DOCUMENT_APPROVE({
        data: { id: this.dataTable.content[0].registrationDocument.id }
      })
      await this.GET_DATA_DOCUMENT()
      // this.$router.push('/profile')
      window.location.reload()
    },
    triggerAction(action, item, type) {
      let updateAction = null
      if (type === 'document') {
        updateAction = 'FINISH'
      }
    },
    async getHistory() {
      this.documentHistory = await this.GET_HISTORY_DOCUMENT_BY_ID(this.dataTable.content[0].registrationDocument.id)
    },
    showCOmmentFunct() {
      this.showComment = true
      this.comment = ''
    },
    errorMessage(message) {
      this.$toasted.show(message, {
        position: 'top-center',
        type: 'error',
        duration: 1000,
        theme: 'bubble',
        singleton: true
      })
    }
  }
}
</script>
<style scoped>
.drop:hover .dropdowns {
  visibility: visible;
  opacity: 1;
  padding-top: 8px;
}
.dropdowns {
  visibility: hidden;
  opacity: 0;
  padding-top: 0;
  transition: all 300ms ease-out;
}
/* Modal Approve */
.modalApprove {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 650px;
  padding: 25px 40px;
  background-color: white;
  border-radius: 8px;
}
/* Background belakang modal History */
.modal-mask-Approve {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* background-color: red; */
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper-Approve {
  display: table-cell;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.5);
}
/* End Background Belakang Modal */
/* Style Modal */
.modal-dialog-Approve {
  top: 5px;
  left: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 650px;
  border-radius: 8px;
}
.modal-content-Approve {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 650px;
  border: none;
  border-radius: 8px;
}
/* End Modal Approve */
.modalComment {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 700px;
  padding: 50px 40px;
  height: 500px;
  background-color: white;
  border-radius: 8px;
}
/* Modal ChangeFile */
.modalChangeFile {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 700px;
  padding: 30px 40px;
  height: auto;
  background-color: white;
  border-radius: 8px;
}
/* Background belakang modal History */
.modal-mask-ChangeFile {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* background-color: red; */
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper-ChangeFile {
  display: table-cell;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.5);
}
/* End Background Belakang Modal */
/* Style Modal */
.modal-dialog-ChangeFile {
  top: 5px;
  left: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 700px;
  border-radius: 8px;
}
.modal-content-ChangeFile {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 700px;
  border: none;
  border-radius: 8px;
}
/* End Modal ChangeFile */
/* modal History */
.modalHistory {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 800px;
  padding: 50px 30px;
  height: 500px;
  background-color: white;
  border-radius: 8px;
}
/* Background belakang modal History */
.modal-mask-History {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* background-color: red; */
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper-History {
  display: table-cell;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.5);
}
/* End Background Belakang Modal */
/* Style Modal */
.modal-dialog-History {
  top: 5px;
  left: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 800px;
  border-radius: 8px;
}
.modal-content-History {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 800px;
  border: none;
  border-radius: 8px;
}
</style>
