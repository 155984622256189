var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "min-h-contain px-5"
  }, [_c('div', {
    staticClass: "pb-3"
  }, [_c('div', {
    staticClass: "text-left text-3xl font-bold flex"
  }, [_c('p', {
    staticClass: "cursor-pointer",
    on: {
      "click": function click($event) {
        return _vm.$router.push('/document-client');
      }
    }
  }, [_vm._v("Documents /")]), _c('p', {
    staticClass: "cursor-pointer ml-2",
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(_vm._s(_vm.documentType.split(' ')[0]) + " /")]), _c('p', {
    staticClass: "text-3xl font-bold text-yellow-500 ml-2"
  }, [_vm._v("Details")])])]), _c('div', {
    staticClass: "bg-white mt-5 w-full px-7 py-5 flex justify-between items-center rounded-lg"
  }, [_c('div', [_c('table', {
    staticClass: "text-left"
  }, [_c('tr', [_c('th', [_vm._v("Document Name")]), _c('td', {
    staticClass: "pl-2"
  }, [_vm._v(_vm._s(_vm.toDocumentName(_vm.dataTable.content[0].fileUrl)))])]), _c('tr', [_c('th', [_vm._v("Type")]), _c('td', {
    staticClass: "pl-2"
  }, [_vm._v(_vm._s(_vm.documentType))])]), _c('tr', [_c('th', [_vm._v("Last Modified")]), _c('td', {
    staticClass: "pl-2"
  }, [_vm._v(_vm._s(_vm.moment(_vm.dataTable.content[0].updatedDate)))])])])]), _vm.afterSave === true && _vm.dataTable.content[0].registrationDocument.type != 'IMAGE' ? _c('div', {
    staticClass: "flex gap-5"
  }, [_c('button', {
    class: "".concat(!_vm.Approve ? 'bg-yellow' : 'bg-green-200', " w-36 p-3 font-semibold  text-white text-center rounded-lg"),
    on: {
      "click": _vm.approveSucces
    }
  }, [_vm._v(" " + _vm._s(!_vm.Approve ? 'Agree' : 'Approved') + " ")]), _c('div', {
    staticClass: "relative inline-block drop"
  }, [_vm._m(0), _c('div', {
    staticClass: "dropdowns absolute z-10"
  }, [_c('button', {
    staticClass: "w-40 p-3 font-semibold bg-neutral-200 text-black text-center rounded-lg hover:bg-neutral-300",
    on: {
      "click": function click($event) {
        _vm.showChangeFile = true;
      }
    }
  }, [_vm._v("Change File")]), _c('button', {
    staticClass: "w-40 p-3 font-semibold bg-neutral-200 text-black text-center rounded-lg hover:bg-neutral-300",
    on: {
      "click": _vm.showCOmmentFunct
    }
  }, [_vm._v("Comment")])])]), _c('button', {
    staticClass: "p-3 bg-yellow rounded-lg",
    on: {
      "click": function click($event) {
        _vm.showModalHistory = true;
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/change.png"),
      "alt": ""
    }
  })])]) : _vm._e()]), _vm.showChangeFile === true ? _c('div', [_c('transition', {
    attrs: {
      "name": "model"
    }
  }, [_c('div', {
    staticClass: "modal-mask-ChangeFile"
  }, [_c('div', {
    staticClass: "modal-wrapper-ChangeFile"
  }, [_c('div', {
    staticClass: "modal-dialog-ChangeFile"
  }, [_c('div', {
    staticClass: "modal-content-ChangeFile"
  }, [_c('div', {
    staticClass: "modalChangeFile"
  }, [_c('div', {
    staticClass: "text-center mb-6 justify-center"
  }, [_c('p', {
    staticClass: "text-2xl font-bold mb-5"
  }, [_vm._v("Change Document")]), _c('p', {
    staticClass: "text-14 font-normal"
  }, [_vm._v("Please state your reason, and we will reach out to you soon")])]), _c('div', {
    staticClass: "border-solid"
  }, [!this.fileList.length ? _c('div', {
    staticClass: "border-dashed border-2 w-3/5 px-8 py-5 text-center m-auto mb-4 rounded-lg",
    on: {
      "drop": function drop($event) {
        $event.preventDefault();
        return _vm.dragFile.apply(null, arguments);
      },
      "dragover": [function ($event) {
        $event.preventDefault();
      }, _vm.dragover]
    }
  }, [_c('img', {
    staticClass: "block mx-auto",
    attrs: {
      "src": require("@/assets/uploadImage.png")
    }
  }), _c('p', {
    staticClass: "my-4 text-sm"
  }, [_vm._v(" Drag & Drop File "), _c('br'), _vm._v(" Or ")]), _c('input', {
    ref: "file",
    staticClass: "w-px h-px opacity-0 overflow-hidden absolute",
    attrs: {
      "type": "file",
      "id": "file"
    },
    on: {
      "change": _vm.onChange
    }
  }), _c('label', {
    staticClass: "cursor-pointer border px-10 py-2 font-semibold bg-yellow text-white text-center rounded-lg",
    attrs: {
      "for": "file"
    }
  }, [_vm._v("Select File")])]) : _vm._e(), this.fileList.length ? _c('div', {
    staticClass: "border-dashed border-2 w-3/5 px-8 py-5 m-auto mb-4 relative"
  }, [_c('p', {
    staticClass: "cursor-pointer bg-red-400 w-6 h-6 text-base font-semibold text-center text-white rounded-full absolute right-5",
    on: {
      "click": _vm.remove
    }
  }, [_vm._v("x")]), _vm._l(_vm.fileList, function (data) {
    return _c('div', {
      key: data.id,
      staticClass: "w-full h-auto py-8"
    }, [_c('p', {
      staticClass: "h-auto text-center truncate ... w-full"
    }, [_vm._v(_vm._s(data.name))])]);
  })], 2) : _vm._e()]), _c('div', {
    staticClass: "w-3/5 m-auto mb-4"
  }, [_c('p', {
    staticClass: "px-4 py-1 text-xs font-medium"
  }, [_vm._v("Comment")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.comment,
      expression: "comment"
    }],
    staticClass: "h-52 text-sm border-grey-field border resize-none px-3 py-3 focus:outline-none focus:ring focus:ring-white w-full rounded-lg",
    domProps: {
      "value": _vm.comment
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.comment = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "flex justify-center gap-10"
  }, [_c('button', {
    staticClass: "w-28 h-9 font-semibold bg-yellow text-white text-center rounded-lg",
    on: {
      "click": _vm.saveChangeFile
    }
  }, [_vm._v("Save")]), _c('button', {
    staticClass: "w-28 h-9 font-semibold border-yellow-400 border-2 text-yellow text-center rounded-lg",
    on: {
      "click": _vm.cancelChangeFile
    }
  }, [_vm._v("Cancel")])])])])])])])])], 1) : _vm._e(), _vm.showComment === true ? _c('div', [_c('transition', {
    attrs: {
      "name": "model"
    }
  }, [_c('div', {
    staticClass: "modal-mask-ChangeFile"
  }, [_c('div', {
    staticClass: "modal-wrapper-ChangeFile"
  }, [_c('div', {
    staticClass: "modal-dialog-ChangeFile"
  }, [_c('div', {
    staticClass: "modal-content-ChangeFile"
  }, [_c('div', {
    staticClass: "modalComment"
  }, [_c('div', {
    staticClass: "text-center mb-6 justify-center"
  }, [_c('p', {
    staticClass: "font-bold mb-5 text-2xl"
  }, [_vm._v("Comment on File")]), _c('p', {
    staticClass: "text-14 font-normal"
  }, [_vm._v("Please state your reason, and we will reach out to you soon")])]), _c('div', {
    staticClass: "w-3/5 m-auto mb-4"
  }, [_c('p', {
    staticClass: "px-4 py-1 text-xs font-medium"
  }, [_vm._v("Comment")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.comment,
      expression: "comment"
    }],
    staticClass: "h-52 text-sm border-grey-field border resize-none px-3 py-3 focus:outline-none focus:ring focus:ring-white w-full rounded-lg",
    domProps: {
      "value": _vm.comment
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.comment = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "flex justify-center gap-10"
  }, [_c('button', {
    staticClass: "w-28 h-9 font-semibold bg-yellow text-white text-center rounded-lg",
    on: {
      "click": _vm.saveComment
    }
  }, [_vm._v("Save")]), _c('button', {
    staticClass: "w-28 h-9 font-semibold border-yellow-400 border-2 text-yellow text-center rounded-lg",
    on: {
      "click": function click($event) {
        _vm.showComment = false;
      }
    }
  }, [_vm._v("Cancel")])])])])])])])])], 1) : _vm._e(), _vm.showApprove === true ? _c('div', [_c('transition', {
    attrs: {
      "name": "model"
    }
  }, [_c('div', {
    staticClass: "modal-mask-Approve"
  }, [_c('div', {
    staticClass: "modal-wrapper-Approve"
  }, [_c('div', {
    staticClass: "modal-dialog-Approve"
  }, [_c('div', {
    staticClass: "modal-content-Approve"
  }, [_c('div', {
    staticClass: "modalApprove"
  }, [_c('div', {
    staticClass: "text-center mb-6 justify-center"
  }, [_c('p', {
    staticClass: "text-xl font-semibold"
  }, [_vm._v("Change Document?")]), _c('p', {
    staticClass: "text-11"
  }, [_vm._v("Please state your reason")])]), _c('div', {
    staticClass: "boxDropzone"
  }, [_c('drop-zone'), _c('SelectFile')], 1), _c('div', {
    staticClass: "comment"
  }, [_c('p', [_vm._v("Comment")]), _c('form', [_c('input', {
    attrs: {
      "type": "text"
    }
  })])]), _c('div', {
    staticClass: "flex items-center justify-evenly"
  }, [_c('button', {
    staticClass: "w-32 p-3 font-semibold bg-yellow text-white text-center rounded-lg",
    on: {
      "click": _vm.ApproveSucces
    }
  }, [_vm._v("Save")]), _c('button', {
    staticClass: "w-32 p-3 font-semibold bg-yellow-secondary text-yellow text-center rounded-lg",
    on: {
      "click": function click($event) {
        _vm.showApprove = false;
      }
    }
  }, [_vm._v("Cancel")])])])])])])])])], 1) : _vm._e(), _vm.showModalHistory === true ? _c('div', [_c('transition', {
    attrs: {
      "name": "model"
    }
  }, [_c('div', {
    staticClass: "modal-mask-History"
  }, [_c('div', {
    staticClass: "modal-wrapper-History"
  }, [_c('div', {
    staticClass: "modal-dialog-History"
  }, [_c('div', {
    staticClass: "modal-content-History"
  }, [_c('div', {
    staticClass: "modalHistory overflow-y-auto"
  }, [_c('div', {
    staticClass: "w-full relative"
  }, [_c('span', {
    staticClass: "cursor-pointer mb-2 right-0 -top-5 absolute",
    on: {
      "click": function click($event) {
        _vm.showModalHistory = false;
      }
    }
  }, [_c('strong', [_vm._v("✕")])])]), _c('div', {
    staticClass: "text-center mb-8 justify-center"
  }, [_c('p', {
    staticClass: "font-bold mb-5 text-2xl"
  }, [_vm._v("Document History")])]), _c('div', {
    staticClass: "m-auto"
  }, [_c('div', {
    staticClass: "flex w-full"
  }, [_c('span', {
    staticClass: "text-sm font-semibold",
    staticStyle: {
      "width": "38%"
    }
  }, [_vm._v("Modified By")]), _c('span', {
    staticClass: "w-1/5 text-sm font-semibold"
  }, [_vm._v("Date")]), _c('span', {
    staticClass: "w-1/5 text-sm font-semibold"
  }, [_vm._v("Comment")]), _c('span', {
    staticClass: "w-1/5 text-sm font-semibold"
  }, [_vm._v("Status")])]), _c('hr'), _vm._l(_vm.documentHistory, function (data, idx) {
    return _c('div', {
      key: idx,
      staticClass: "flex py-2 w-full"
    }, [_c('span', {
      staticClass: "text-sm font-semibold leading-snug",
      staticStyle: {
        "width": "38%"
      }
    }, [_vm._v(_vm._s(data.createdBy))]), _c('span', {
      staticClass: "text-sm font-medium w-1/5"
    }, [_vm._v(_vm._s(_vm.moment(data.createdDate)))]), _c('span', {
      staticClass: "text-sm font-medium w-1/5"
    }, [_vm._v(_vm._s(data.comment ? data.comment : '-'))]), _c('span', {
      staticClass: "text-sm font-medium w-1/5"
    }, [_vm._v(_vm._s(data.status ? data.status : '-'))])]);
  })], 2)])])])])])])], 1) : _vm._e(), _c('div', {
    staticClass: "w-full py-5 -mt-44 flex justify-between items-center"
  }, [_c('iframe', {
    staticStyle: {
      "height": "700px",
      "width": "100%",
      "margin-top": "200px"
    },
    attrs: {
      "type": "application/pdf",
      "src": _vm.dataTable.content[0].fileUrl
    }
  })])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "w-40 p-3 font-semibold bg-yellow-secondary text-yellow text-left pl-6 rounded-lg relative"
  }, [_vm._v("Respond"), _c('img', {
    staticClass: "absolute right-8 top-5",
    attrs: {
      "src": require("../../assets/Vector.png")
    }
  })]);
}]

export { render, staticRenderFns }